import style_1_theme_1 from './style_1_theme_1';
import style_1_theme_2 from './style_1_theme_2';
import style_1_theme_3 from './style_1_theme_3';
import style_1_theme_4 from './style_1_theme_4';
import style_1_theme_5 from './style_1_theme_5';
import style_2_theme_1 from './style_2_theme_1';
import style_2_theme_2 from './style_2_theme_2';
import style_2_theme_3 from './style_2_theme_3';
import style_2_theme_4 from './style_2_theme_4';
import style_2_theme_5 from './style_2_theme_5';
import style_4_theme_1 from './style_4_theme_1';
import style_4_theme_2 from './style_4_theme_2';
import { setColors } from './custom_color';
import { setTheme } from '../../../utils/skins-utils';
const themes = {
  style_1_theme_1,
  style_1_theme_2,
  style_1_theme_3,
  style_1_theme_4,
  style_1_theme_5,
  style_2_theme_1,
  style_2_theme_2,
  style_2_theme_3,
  style_2_theme_4,
  style_2_theme_5,
  style_4_theme_1,
  style_4_theme_2
};
export default {
  setColor(color, theme) {
    return Object.assign(themes[theme], setColors(color));
  },
  setTheme,
  themes
};
