let customColor = {
  '--color_pc_text_active': '#fb2626',
  '--background_pc_color_active': '#fb2626'
};
export const setColors = color => {
  customColor = {
    '--color_pc_text_active': color,
    '--background_pc_color_active': color
  };
  return customColor;
};
