let customColor = {
  '--color_text_active': '#fb2626',
  '--background_color_active': '#fb2626'
};
export const setColors = color => {
  customColor = {
    '--color_text_active': color,
    '--background_color_active': color
  };
  return customColor;
};
